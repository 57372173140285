import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Uncg1 from '../images/UNCG1.jpg'
import Uncg2 from '../images/UNCG2.jpg'
import Uncg3 from '../images/UNCG3.jpg'
import Uncg4 from '../images/UNCG4.jpg'
import uncgLogo from '../images/UNCGLOGO.png'
import Video from '../images/uncgVideo.jpg'
import Video2 from '../images/uncg2Video.jpg'


export default () => (
  <div id="app" className="workpage">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh UNCG Page"></meta>
          <title>UNCG | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/uncg" />
        </Helmet>

      <PreHeader/>
      <Header/>

        <div className="company_heading">
          <span className="company_heading--name">UNCG + WRALDS</span>
          <h1 className="company_heading--tagline">
          Putting a spin on “business as usual” for this university
          </h1>
        </div>
        <img className="company_gallery--image" src = {Uncg1} alt = "UNCG "/>
        <div className="company_grid">
          <div className="company_overview">
            <div className="company_overview--heading">OVERVIEW</div>
            <p className="company_overview--text">
            The UNC-Greensboro Bryan School of Business and Economics
            came to us with a problem — how could they communicate each
            of their distinctive business school programs in an
            impactful way? Our solution? We created seven custom motion
            graphic videos that highlighted each of their
            program’s unique offerings using:
            </p>
            <ul className="company_overview--list">
              <li>Graphic Design</li>
              <li>Copywriting</li>
              <li>Custom Motion Graphic Video Production</li>
            </ul>
          </div>
          <img className="company_logo" src = {uncgLogo} alt ="UNCG service company logo"/>

          <div className="company_gallery">
            <img className="company_gallery--image three" src = {Uncg2} alt ="UNCG "/>
            <img className="company_gallery--image one" src = {Uncg3} alt ="UNCG "/>
            <img className="company_gallery--image one" src = {Uncg4} alt ="UNCG "/>
            <a href="https://vimeo.com/374501448" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video} alt ="UNCG video "/>
            </a>
            <a href="https://vimeo.com/374501240" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video2} alt ="UNCG video "/>
            </a>
          </div>
        </div>
    <Footer/>
  </div>
)
